
// import { find, findId, update } from "../../api/category.js";
// import { list } from "../../api/model.js";
import { defineComponent } from "vue";
import { pinyin } from "pinyin-pro";
import { client } from '@/client';
import { CategoryStatus, CategoryTarget, CategoryType } from "@/shared/types/CategoryData";
import { ElMessage } from "element-plus";
import { addLabelValue, tree,treeById } from "@/utils/tools";
export default defineComponent({
  name: "category-edit",
  data: () => {
    return {
      id: 0, //栏目
      categorySelected: [] as number[], //-1默认选中顶级栏目
      categoryProps: { checkStrictly: true },

      activeName: "first", //tab 默认显示第一个
      activeIndex: 0, //tab 内容默认显示第一个

      category: [] as any[], //当前所有栏目
      modList: [] as any[], //模型列表
      params: {
        //接口入参
        pid: 0,
        seo_title: "",
        seo_keywords: "",
        seo_description: "",
        title: "",
        pinyin: "",
        path: "",
        mid: 0,
        description: "",
        url: "",
        sort: 0,
        type: CategoryType.column,
        target: CategoryTarget.current,
        status: CategoryStatus.SHOW,
      },
      paramsRules: {
        //校验规则
        title: [
          { required: true, message: "请输入栏目名称", trigger: "blur" },
          {
            min: 2,
            max: 10,
            message: "名称长度在 2 到 10 个字符之间",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    allPath() {
      if (this.params.path == "") {
        return this.params.path + "/" + this.params.pinyin;
      } else {
        return this.params.path + this.params.pinyin;
      }
    },
  },
  watch: {
    "params.title": function (newv, oldv) {
      this.params.pinyin = pinyin(newv, { toneType: "none" }).replace(
        /\s+/g,
        ""
      );
      let path = this.params.path;
      let pathArr = path.split("/");
      pathArr.length = pathArr.length - 1;
      pathArr.push(this.params.pinyin);
      this.params.path = pathArr.join("/");
    },
  },
  created() {
    let id = this.$route.params.id as string;
    this.id = parseInt(id);
    this.modelList();
    this.query();
    this.findById();
  },

  methods: {
    handleClick(tab: { index: number; }) {
      this.activeIndex = tab.index;
    },

    //查询
    async query() {
      try {
        let res = await client.callApi("category/Find",{pageSize:10000});
        if (res.isSucc) {
          let data = res.res.list;
          let ids = treeById(this.id, data);
          if (ids.length > 1) {
            ids.length = ids.length - 1;
          }
          this.categorySelected = ids;
          let end = addLabelValue(tree(data));
          this.category = [...end];
        }
      } catch (error) {
        console.log(error);
      }
    },

    //模型列表
    async modelList() {
      try {
        let res = await client.callApi("model/List",{pageSize:1000});
        if (res.isSucc) {
          this.modList = res.res.list;
        }
      } catch (error) {
        console.log(error);
      }
    },

    //查
    async findById() {
        let res = await client.callApi("category/Detail",{id:this.id});
        // let res = await findId(this.id);
        if (res.isSucc) {
          this.params = res.res;
        }
    },

    handleChange(e: string|any[]) {
      if (e[0] != -1) {
        this.params.pid = e[e.length - 1];
      }
    },

    //更新基本信息
    async update() {
      try {
        let res = await client.callApi("category/Update",{
          ...this.params,
          id:this.id,
        });
        if (res.isSucc) {
          ElMessage({
            message: "更新成功^_^",
            type: "success",
          });
          this.$router.go(-1);
        }
      } catch (error) {
        console.log(error);
      }
    },

    submit(formName: string|number) {
      this.params.path = this.allPath;
      let refs:any = this.$refs[formName];

      refs.validate((valid: any) => {
      console.error(this.allPath,this.params)
        if (valid) {
          this.update();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
});
